<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Warehouse Branch
            <v-spacer></v-spacer>
            <add-button permission="subject-read" @action="form.dialog = true"
              >New Branch
            </add-button>
          </v-card-title>
          <v-card flat>
            <v-card outlined>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    hint="Atleast 3 characters"
                    color="primary"
                    class="pa-0"
                    label="Search By Name"
                    v-model="searchAH"
                    @input="searchBranch"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :footer-props="footerProps"
            :options.sync="pagination"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td style="width: 8%" class="text-xs-left">{{ ++index }}</td>
                <td style="font-weight: bold" class="text-xs-left">
                  {{ item.name }}
                </td>
                <td class="text-right">
                  <span>
                    <edit-button
                      permission="subject-read"
                      @agree="form.edit(item), editData(item)"
                    />
                    <delete-button
                      permission="section-create"
                      @agree="form.delete(item.id)"
                    />
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="form.dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="title primary white--text"
          >Branch Information</v-card-title
        >
        <v-card-text>
          <v-form
            style="margin-left: -12px; margin-top: 10px"
            ref="form"
            @submit.prevent="save"
            @keydown.native="form.errors.clear($event.target.name)"
            @keyup.enter="save"
            lazy-validation
          >
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs8>
                  <v-text-field
                    outlined
                    dense
                    v-model="form.name"
                    name="name"
                    label="Name"
                    :error-messages="form.errors.get('name')"
                    :height="25"
                    autocomplete="off"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions style="padding-bottom: 20px">
          <v-spacer />
          <v-btn
            color="warning"
            outlined
            text
            @click="(dialog = false), form.reset()"
            >Cancel</v-btn
          >
          <v-btn color="success" outlined text @click="save">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
export default {
  data: () => ({
    form: new Form(
      {
        name: '',
      },
      '/api/account/inventory/branch'
    ),
    searchAH: null,
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: 'Name', value: 'name', align: 'left', width: '30%' },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        align: 'right',
        width: 120,
      },
    ],
    entries: [],
    accountHeads: [],
    pagination: {
      rowsPerPage: 10,
    },
    footerProps: {
      'items-per-page-options': [10, 25, 50, 100, -1],
    },
    // dialog: false,
  }),
  computed: {
    ...mapState(['batch']),
    items() {
      return this.entries.map((entry) => {
        return { Name: entry.name, id: entry.id };
      });
    },
  },
  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    search(val) {
      if (!val) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.$rest
          .get('/api/account/inventory/branch?search=' + val)
          .then(({ data }) => {
            this.entries = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },
  mounted() {},

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&search=' +
        this.searchAH
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.accountHeads = data.data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      });
    },
    editData(data) {
      this.entries = [];
      this.entries.push({ name: data.name, id: data.id });
    },
    searchBranch: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.get();
      }, 500);
    },
    save() {
      this.form
        .store()
        .then(() => {
          this.form.dialog = false;
          this.$events.fire('notification', {
            message: res.data.message,
            status: 'success',
          });
        })
        .catch((err) => {
          this.form.dialog = false;
          this.form.name = '';
          this.$events.fire('notification', {
            message: err.response.message,
            status: 'error',
          });
        })
        .finally(() => {
          this.form.dialog = false;
        });
    },
  },
};
</script>
